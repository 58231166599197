<template>
    <div>
        <div class="info-box">
            <div style="border: 0.1rem solid #bcbcbc">
                <div class="check_title">
                    <p>———— 订单信息 ————</p>
                </div>
                <div class="line"></div>
                <div v-if="id">
                    <div class="check_information">
                        <p>id：</p>
                        <p>{{id}}</p>
                    </div>
                    <div class="line"></div>
                </div>
                <div class="check_information" v-if="order.order_id">
                    <p>订单号：</p>
                    <p>{{order.orderID}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information" v-if="order.order_id">
                    <p>套餐名：</p>
                    <p>{{order.gooods_name}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>姓名：</p>
                    <p>{{order.name}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>性别：</p>
                    <p>{{order.sex}}</p>
                </div>

                <div class="line"></div>
                <div class="check_information">
                    <p>预约手机：</p>
                    <p>{{order.phone}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>预约人数：</p>
                    <p>{{order.number}}</p>
                </div>

                <div class="line"></div>
                <div class="check_information">
                    <p>预约日期：</p>
                    <p>{{order.date}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>预约时间段：</p>
                    <p>{{order.time}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>状态：</p>
                    <p>{{order.statusText}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>备注：</p>
                    <p>{{order.remark}}</p>
                </div>

            </div>
        </div>
        <div class="btn-box">
            <router-link class="container-div p_key" to="/check/reserveList">返回</router-link>
            <!--<p v-if="order.status == 0" class="p_key" @click="reserve(-3)">拒绝预约</p>-->
            <!--<p v-if="order.status == 0" class="p_key" @click="reserve(1)">接收预约</p>-->
            <p v-if="order.status == 1 && !order.order_id" class="p_key" @click="reserve(2)">核销</p>
        </div>
    </div>
</template>

<script>
    import { moneyFen2Yuan, orderStatusStr } from '../../utils/tools'
    import { globalConfig } from '../../utils/mixin'
    export default {
        name: 'Check',
        mixins: [globalConfig],
        components: {},
        data () {
            return {
                id: '',
                sign: '',
                type: '',
                code: '',
                url: '',
                checkUrl: '',
                order: [],
                permission: [],
                remark: ''
            }
        },
        methods: {
            moneyFen2Yuan,
            orderStatusStr,
            reserve: function (e) {
                const that = this
                this.axios.post(this.apiUrl + this.checkUrl, {
                    id: this.id,
                    status: e
                }).then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 1) {
                        this.$toast({
                            message: data.msg,
                            onClose: function () {
                                that.$router.push('/check/reserveList')
                            }
                        })
                    } else {
                        this.$router.push('/check/warning/' + data.msg)
                    }
                })
            },
            init: function () {
                this.axios.post(this.apiUrl + this.url, {
                    id: this.id
                }).then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 1) {
                        this.order = data.order
                    } else {
                        this.$router.push('/check/warning/' + data.msg)
                    }
                })
            }
        },
        created () {
            this.id = this.$route.params.id
            this.url = 'checkh5/order/getReserveInfo'
            this.checkUrl = 'checkh5/order/reserve'
            this.init()
        },
        mounted () {
        },
        computed: {
        }
    }
</script>

<style scoped>
    body{
        background: #f3f3f3;
    }
    .check_title{
        display: flex;
        justify-content: space-around;
        height: 10vw;
        align-items: center;
        border: 0.1rem solid #bcbcbc;
        padding: 1rem 0;
    }
    .check_title p{
        font-size: 4vw;
        font-weight: 500;
        color: #C20B09;
        margin-left: 1.8vw;
    }
    .check_information{
        padding: 1vw 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 3.4vw;
        color: #666666;
    }
    .check_information1{
        font-weight: 900;color:#C20B09
    }
    .check_information p:first-child{
        margin-left: 2rem;
    }
    .check_information p:nth-child(2){
        width: 50vw;
        margin-right: 2rem;
        text-align: right;
    }
    .line{
        width: 88vw;
        height: 1px;
        margin-left: 6vw;
        background: #f4f4f4;
    }
    .check_information_number p{
        width: 5vw;
        height: 5vw;
        text-align: center;
        line-height: 5vw;
        background-color: #eee;
        margin: 0 0.8vw;
        border-radius: 1vw;
    }
    .info-box {
        background-color: white;
        width: 95%;
        margin: 2rem auto;
    }
    .check_information{
        padding: 1vw 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 3.4vw;
        color: #666666;
    }
    .check_information p:first-child{
        margin-left: 2rem;
    }
    .check_information p:nth-child(2){
        width: 50vw;
        margin-right: 2rem;
        text-align: right;
    }
    .line{
        width: 88vw;
        height: 1px;
        margin-left: 6vw;
        background: #f4f4f4;
    }
    .p_key{
        border-radius: 0.5rem;
        background-color: #C20B09;
        color: white;
        text-align: center;
        font-size: 1rem;
        padding: 0.5rem 2rem;
    }
    .btn-box {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
    }
    .check_information_number{
        display: flex;
    }
    .check_information_number p{
        width: 5vw;
        height: 5vw;
        text-align: center;
        line-height: 5vw;
        background-color: #eee;
        margin: 0 0.8vw;
        border-radius: 1vw;
    }
    .btn-remark input{
        width: 70vw;
        height: 6vw;
        border: 1px solid #999;
        border-radius: 1vw;
        text-indent: 2vw;
    }
</style>
